import React, {useEffect, useState, useRef, useMemo, useCallback, createRef} from 'react';
import PropTypes from 'prop-types';
import styles from './styles/CleverTable.module.scss';
import {CSSTransition} from "react-transition-group";
import classNames from 'classnames';
import InfiniteScroller from "../../header/InfiniteScroller";
import TrackVisibility from "react-on-screen";
import {useMachine} from "@xstate/react";
import {FetchMachine} from "../../machines/FetchMachine";
import FontAwesome from "../FontAwesome";
import Scrollbar from "react-scrollbars-custom";
import {reactScrollBarsCustomWidth} from "../../../utils/SchoolBlocksUtilities";

export default function CleverTable(props) {
    const {
        transitionIn = true,
        data = [],
        columns = [],
        className = "",
        maxHeight = 400,
        initialWidthIsPercent = false,
        useInfiniteScroll = false,
    } = props;
    const [columnWidths, setColumnWidths] = useState(columns.map(c => c.initialWidth));

    const [dragging, setDragging] = useState(false);
    const [currentDragColumn, setCurrentDragColumn] = useState(null);
    const [dragStart, setDragStart] = useState(null);
    const [initialWidths, setInitialWidths] = useState(null);
    const [currentDragPosition, setCurrentDragPosition] = useState(null);
    const [current, send] = useMachine(FetchMachine);
    const transitionRefs = useRef(data.map(() => createRef()));
    const transitionRef = useRef(null);

    async function handleFetchMore() {
        send("FETCH");
        await props.handleFetchMore();
        send("FULFILL");
    }

    const tableRef = useRef(null);

    useEffect(() => {
        if (!dragging) {
            setColumnWidths(columns.map(c => c.initialWidth))
        }
    }, [columns]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        }
    }, [dragging]);

    useEffect(() => {
        let dragged; // positive if dragged left, negative if dragged right
        if (initialWidthIsPercent) {
            dragged = ((dragStart - currentDragPosition) / tableRef.current?.clientWidth) * 100;
        } else {
            dragged = dragStart - currentDragPosition;
        }

        if (typeof currentDragColumn === 'number' && currentDragPosition) {
            handleDragColumn(dragged);
        }

    }, [currentDragPosition]);

    const handleDragColumn = useCallback(dragged => {
        setColumnWidths(current => {
            return current.map((c, i) => {
                if (i === currentDragColumn) {
                    return initialWidths[i] - dragged;
                } else if (i === currentDragColumn + 1) {
                    return initialWidths[i] + dragged;
                }
                return initialWidths[i];
            });
        });
    }, [columnWidths, initialWidths, currentDragColumn]);

    const handleMouseDown = useCallback((e, columnIndex) => {
        if (e.button === 0) {
            setDragging(true);
            setCurrentDragColumn(columnIndex);
            setDragStart(e.screenX);
            setInitialWidths(columnWidths);
        }
    }, [columnWidths]);

    const handleMouseUp = useCallback(() => {
        if (dragging) {
            setDragging(false);
            setCurrentDragColumn(null);
            setInitialWidths(null);
            setCurrentDragPosition(null);
            setDragStart(null);
        }
    }, [dragging]);

    const handleMouseMove = useCallback(e => {
        if (dragging) {
            setCurrentDragPosition(e.screenX);
        }
    }, [dragging]);

    const {columnDividers, cumulativeWidth} = useMemo(() => {
        let cumulativeWidth = 0;
        const cols = columns.reduce((acc, curr, idx) => {
            cumulativeWidth += columnWidths[idx];

            if (idx < columns.length - 1) { // don't add a divider for the last column
                acc.push(<span
                    key={curr.dataKey}
                    onMouseDown={e => handleMouseDown(e, idx)}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                    style={{
                        left: cumulativeWidth + (initialWidthIsPercent ? "%" : "px"),
                        height: 50 * (data.length + 1),
                    }}
                />);
            }

            return acc;
        }, []);
        return {columnDividers: cols, cumulativeWidth};
    }, [columns.length, data.length, initialWidthIsPercent, props.height, columnWidths]);

    const containerClassName = classNames({
        [styles.table]: true,
        [className]: true,
    });

    const tableRowClassName = classNames({
        [styles.tableRow]: true,
        [props.tableRowClassName]: props.tableRowClassName,
    })

    const listOfCellHeightsByIndex = data.map(d => {
        return d.height
    });

    return (
        <div
        className={containerClassName}
        ref={tableRef}
        style={{
          height:
            listOfCellHeightsByIndex.reduce((partialSum, height)=> partialSum +height, 0) + (
              1 +
              (data.length === 0 || props.shouldFetchMore ? 1 : 0)) *
            50,
          width: initialWidthIsPercent ? "100%" : `${cumulativeWidth}px`,
          maxHeight: maxHeight,
        }}
      >
            <div className={styles.columnDividerContainer}>{columnDividers}</div>
            <div
          className={styles.tableRow}
          style={{
            width: initialWidthIsPercent ? "100%" : `${cumulativeWidth}px`, // 4rem handles the margin of each row
          }}
        >
                {columns.map((c, i) => {
            let width;
            if (initialWidthIsPercent) {
              // account for scrollbar of 10px
              width = `calc(${columnWidths[i]}% - ${10 /
                columns.length}px)`;
            } else {
              width = columnWidths[i] + "px";
            }
            return (
                <div
                className={styles.tableCell}
                style={{
                  width,
                  height: '50px',
                }}
                key={c.dataKey}
              >
                    {c.title}
                </div>
            );
          })}
            </div>
            {data.length === 0 && props.noDataMessage && (
                <div
            style={{
              textAlign: "center",
              paddingTop: "1rem",
              width: "100%",
            }}
          >
                    {props.noDataMessage}
                </div>
        )}
            <Scrollbar
                scrollbarWidth={reactScrollBarsCustomWidth}
                disableTrackYWidthCompensation={true}
                style={{ height: `calc(100% - ${50}px)` }}
        >
                {data.map((d, i) => {
            return (
                <CSSTransition
                    nodeRef={transitionRefs.current[i]}
                    appear
                    key={d.key}
                    data-selected={d.key === props.selectedItemKey}
                    in={transitionIn}
                    timeout={250}
                    classNames={props.transitionClassNames}
              >
                    <div
                        ref={transitionRefs.current[i]}
                  className={tableRowClassName}
                  tabIndex={d.onClick ? 0 : -1}
                  role={d.onClick ? "button" : "none"}
                  style={{
                    transform: `translateY(${listOfCellHeightsByIndex.slice(0,i).reduce((partialSum, height)=> partialSum +height, 0)}px)`,
                    height: listOfCellHeightsByIndex[i],
                    width: initialWidthIsPercent
                      ? "100%"
                      : `${cumulativeWidth}px`,
                    cursor: d.onClick ? "pointer" : "inherit",
                    ...d.style,
                  }}
                  onKeyDown={e => {
                    if (e.which === 13 && d.onClick) {
                      d.onClick();
                    }
                  }}
                  onClick={() => {
                    if (d.onClick) d.onClick();
                  }}
                >
                        {columns.map((c, i) => (
                            <div
                      className={styles.tableCell}
                      key={c.dataKey}
                      style={{
                        width:
                          columnWidths[i] +
                          (initialWidthIsPercent ? "%" : "px"),
                        height: listOfCellHeightsByIndex[i],
                      }}
                    >
                                {d[c.dataKey]}
                            </div>
                  ))}
                    </div>
                </CSSTransition>
            );
          })}
                <CSSTransition
                    nodeRef={transitionRef}
            timeout={250}
            key={"loadmore"}
            appear
            in={true}
            classNames={props.transitionClassNames}
          >
                    {props.shouldFetchMore && props.handleFetchMore ? (
                        <div
                            ref={transitionRef}
                style={{
                  transform: `translateY(${listOfCellHeightsByIndex.reduce((partialSum, height)=> partialSum+height,0)}px)`,
                  textAlign: "center",
                  height: 50,
                  lineHeight: 50 + "px",
                }}
              >
                            {!useInfiniteScroll && (
                                <button
                    disabled={current.value === "PENDING"}
                    onClick={handleFetchMore}
                  >
                                    {current.value === "PENDING" ? (
                                        <FontAwesome spin prefix={"fas"} name={"fa-spinner"} />
                    ) : (
                      "Load More"
                    )}
                                </button>
                )}
                            {useInfiniteScroll && (
                                <TrackVisibility throttleInterval={2000} offset={275}>
                                    <InfiniteScroller fetchMore={props.handleFetchMore} />
                                </TrackVisibility>
                )}
                        </div>
            ) : (
                <div />
            )}
                </CSSTransition>
            </Scrollbar>
        </div>
    );
}

CleverTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        dataKey: PropTypes.string.isRequired,
        initialWidth: PropTypes.number, // either absolute or percent value, all columns must add up to 100 if using percent
    })),
    initialWidthIsPercent: PropTypes.bool, // pass true if the initial widths should be treated as percents (must be numbers)
    className: PropTypes.string,
    tableRowClassName: PropTypes.string,
    noDataMessage: PropTypes.string,
    handleFetchMore: PropTypes.func,
    shouldFetchMore: PropTypes.bool,
    maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedItemKey: PropTypes.any,
    transitionIn: PropTypes.bool,
    transitionClassNames: PropTypes.any,
    useInfiniteScroll: PropTypes.bool,
};
